import React, { useEffect } from 'react';
import LocalForage from 'localforage';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import teal from '@material-ui/core/colors/teal';
import moment from 'moment-hijri';



import AddIcon from '@material-ui/icons/Add';
import '@material-ui/icons';
import { Box } from '@material-ui/core';
import ReactGA from 'react-ga';

// Initialize google analytics
ReactGA.initialize('UA-113285216-1');
ReactGA.pageview('Durood Counter App - Homepage');
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: teal,
    error: teal
  },

});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButton: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(2),

  },
  count: {
    marginTop: 15,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    flexGrow: 1,
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center'
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}));

// Keeping them as global on purpose
let duroodCount = 0;
let duroodComment = "";
let data = [];
async function removeFromDB(key) {
  if (key) {
    await LocalForage.removeItem(key);
  }
}
async function addToDB() {
  const timestamp = Date.now();
  const string = `${new Date(timestamp).toDateString()} ${new Date(timestamp).toLocaleTimeString()} (${getHijridate()})`;
  const obj = {
    count: parseInt(duroodCount) || 0,
    comment: duroodComment,
    time: timestamp,
    date: string
  }
  if (obj.count) {

    await LocalForage.setItem(timestamp, obj)
  }
}
async function showUpdatedCountAndTable() {
  // const dbData = await LocalForage.getItem();
  let total = 0;
  data = [];
  // TODO: Reduce DB iterations.
  await LocalForage.iterate(function (value, key, iterationNumber) {
    // Resulting key/value pair -- this callback
    // will be executed for every item in the
    // database.


    total = total + parseInt(value.count);
    data.push({
      count: value.count,
      comment: value.comment,
      date: value.date || "",
      time: value.time || ""
    })
  });
  data.sort(function (a, b) { return (a.time < b.time) ? 1 : ((b.time < a.time) ? -1 : 0) });
  return { total, data };

}



function getHijridate() {
  return `${moment().subtract(1, "days").format('iMMMM iDo')}`;
}
function App() {
  const { enqueueSnackbar } = useSnackbar();

  // const handleClick = () => {
  //   enqueueSnackbar('I love snacks.');
  // };

  const showNotification = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
    });
  };
  async function resetCounter() {
    try {

      await LocalForage.clear()
      const result = await showUpdatedCountAndTable();
      showNotification(`Counter reset to 0 Successfully!`, "success");
      const data = result.data;
      const columns = state.columns;
      ReactGA.event({
        category: 'Durood',
        action: 'Reset Durood',
        label: `Reset count to 0`,
        value: 0
      });
      updateTotal(result.total);
      updateDuroodField("");
      updateCommentField("");
      setState({
        columns: columns,
        data: data
      })
      duroodCount = 0;
      duroodComment = "";
    } catch {

    }
  }

  async function add() {
    if (duroodCount) {


      await addToDB(duroodCount, duroodComment);
      const result = await showUpdatedCountAndTable();

      const data = result.data;
      const columns = state.columns;
      ReactGA.event({
        category: 'Durood',
        action: 'Add Durood',
        label: `Add:${duroodCount}, New Total: ${result.total}, Comment: ${duroodComment}`,
        value: duroodCount
      });
      updateTotal(result.total);
      updateDuroodField("");
      updateCommentField("");
      setState({
        columns: columns,
        data: data
      })
      duroodCount = 0;
      duroodComment = "";
      showNotification(`Added Successfully. Your new total is ${result.total}`, "success");
    }
  }

  function onCommentChange(e) {
    duroodComment = e.target.value;
    updateCommentField(e.target.value);
  }

  function onCountChange(e) {
    duroodCount = e.target.value;
    updateDuroodField(e.target.value);
  }
  const [open, setOpen] = React.useState(false);
  const [total, updateTotal] = React.useState(0);
  const [duroodFieldValue, updateDuroodField] = React.useState("");
  const [commendFieldValue, updateCommentField] = React.useState("");
  const [state, setState] = React.useState({
    columns: [
      { title: 'Added at', field: 'date' },
      { title: 'Count', field: 'count', type: 'numeric' },
      { title: 'Comment', field: 'comment' },

    ],
    data: [],
  });
  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = async () => {
    await resetCounter();
    setOpen(false);
  };
  useEffect(() => {
    let ignore = false;
    async function getTableData() {
      const result = await showUpdatedCountAndTable();
      if (!ignore) {
        setState({ columns: state.columns, data: result.data });
        updateTotal(result.total)
      }
    }
    getTableData();

    return () => { ignore = true; }

  }, [state.columns]);

  const classes = useStyles(theme);
  return (
    <>

      <MuiThemeProvider theme={theme}>

        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Durood Sharif Counter
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="h2" component="h2" className={classes.count}>
            {total}
          </Typography>



        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="caption" component="h6" disabled gutterBottom >
            Total Durood Sharif
          </Typography>

        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >

          <div id="duroodField">
            <TextField
              id="outlined-full-width"
              placeholder="0"
              label="Enter Durood Count"
              type="number"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={onCountChange}
              value={duroodFieldValue}

            />
          </div>
          <div id="commentField">
            <TextField
              onChange={onCommentChange}
              id="outlined-basic"
              className={classes.textField2}
              margin="normal"
              placeholder="e.g Recited in morning"
              label="Comment (Optional)"
              type="string"
              style={{ width: "200" }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              value={commendFieldValue}
            />
          </div>
        </Grid>

        <div className={classes.containerButton}>
          <Tooltip title="Add Durood" aria-label="add">
            <Fab

              color="primary"
              onClick={async () => {
                await add();
              }}
              style={{ height: 100, width: 100 }}
              aria-label="add"
              className={classes.margin}>


              <AddIcon
                style={{ fontSize: "2.5rem" }}
              />
            </Fab>
          </Tooltip>
        </div>



        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="subtitle2" component="h5" gutterBottom >

            Today is: {getHijridate()}
          </Typography>

        </Grid>
        <div>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography component="div" gutterBottom >
              <Box color="text.disabled" fontStyle="italic" m={2}>
                Note: Please do not delete "Site Data" while clearing your browser history. Clearing site data will reset the durood counter to 0 and you will lose your durood data.
              </Box>

            </Typography>

          </Grid>
        </div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className={classes.containerButton}>
            <Tooltip title="Reset counter" aria-label="reset">
              <Button

                color="error"
                variant="outlined"
                onClick={async () => {
                  // await resetCounter();
                  await handleClickOpen()
                }}
                style={{ marginBottom: 15 }}
                aria-label="reset"
                className={classes.margin}

              >
                Reset Counter


              </Button>
            </Tooltip>
          </div>
        </Grid>

        <div>
          <MaterialTable
            icons={tableIcons}
            title="Durood History"
            columns={state.columns}
            data={state.data}
            options={{
              actionsColumnIndex: -1
            }}
            editable={{

              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    const objectIndex = state.data.findIndex(obj => obj.comment === oldData.comment && obj.date === oldData.date && obj.count === oldData.count);
                    await removeFromDB(state.data[objectIndex].time);
                    const result = await showUpdatedCountAndTable();

                    const data = result.data;
                    const columns = state.columns;
                    updateTotal(result.total);
                    setState({
                      columns: columns,
                      data: data
                    })
                    ReactGA.event({
                      category: 'Durood',
                      action: 'Remove Durood',
                      label: `Remove:${oldData.count}, New Total: ${result.total}, Comment: ${oldData.comment}`,
                      value: -oldData.count
                    });

                  }, 600);
                }),
            }}
          />
        </div>

        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" color="error">
              {"Reset Counter?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you really want to reset counter to 0? All your existing durood sharif data and count will be lost.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="success" >Don't reset</Button>
              <Button onClick={handleAgree} color="error" autoFocus>
                Yes, Reset
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </MuiThemeProvider>
    </>


  );
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} >
      <App />
    </SnackbarProvider>
  );
}
// export default App;
